import { useCallback, useEffect, useState } from 'react'
import {
  OptionItemInterface,
  ParkingInterface,
  ValidationResultInterface,
  ValidationRulesInterface,
} from '../../../../../common/interfaces/interfaces'
import { getApi } from '../../../../../api/api'
import { useLogout } from '../../../../../common/hooks/useLogout'
import { useSelector } from 'react-redux'
import { selectPartnerList } from '../../../../../store/slices/partnerListSlice'
import { useInput } from '../../../../../common/hooks/useInput'
import { parkingValidations } from '../../../../../constants'
import { useValidation } from '../../../../../common/hooks/useValidation'
import { useCurrentUser } from '../../../../../common/hooks/useCurrentUser'
import { useAbility } from '../../../../../common/hooks/useAbility'
const validations: ValidationRulesInterface[] = parkingValidations

export const useForm = (
  initialValue: ParkingInterface,
  getList: (reUsed?: boolean) => void,
) => {
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [data, setData] = useState<ParkingInterface>(initialValue)
  const [api] = useState(() => getApi())
  const { logOut } = useLogout()
  const [validationResult, setValidationResult] = useState<
    ValidationResultInterface[] | []
  >([])
  const [disabled, setDisabled] = useState<boolean>(true)
  const { currentUser } = useCurrentUser()

  const { checkAbility } = useAbility()

  const partnerList = useSelector(selectPartnerList)

  // useEffect(() => {
  //   if (!currentUser.super && data.partnerId === '')
  //     setData((prevState) => ({
  //       ...prevState,
  //       partnerId: currentUser.partnerId,
  //     }))
  // }, [currentUser])

  const getPartnerList = () => {
    let arr: OptionItemInterface[] = []
    for (const item of partnerList) {
      let pattern: OptionItemInterface = {
        id: item.id,
        label: item.name,
        name: item.name,
        value: item.id,
      }
      arr.push(pattern)
    }
    return arr
  }

  const { validate, checkAndAddValidation, setDirty } = useValidation(
    setValidationResult,
    validationResult,
    validations,
  )

  useEffect(() => {
    currentUser.id &&
      !currentUser.super &&
      setData((prevState) => ({
        ...prevState,
        partnerId: currentUser.partnerId,
      }))
  }, [partnerList])

  useEffect(() => {
    for (var item in data) {
      if (validations.map((el) => el.fieldName).includes(item)) {
        checkAndAddValidation(item)
      }
    }
  }, [checkAndAddValidation, data])

  useEffect(() => {
    for (var item in data) {
      if (validations.map((el) => el.fieldName).includes(item)) {
        validate(item, data[item])
      }
    }
  }, [data])

  useEffect(() => {
    setDisabled(validationResult.filter((el) => el.notValid).length > 0)
  }, [validationResult])

  const { inputHandler } = useInput(setData)

  const getValidationResult = (fieldName: string) => {
    return validationResult.find((el) => el.name === fieldName)
  }

  const createOrUpdateAction = useCallback(async () => {
    try {
      if (data.id !== '') {
        await api.updateParking(data)
      } else {
        const { id, ...rest } = data
        await api.createParking({ ...rest })
      }
      await getList(true)
    } catch (error: any) {
      if (error.response &&  error.response.status === 401) logOut().then()
    }
  }, [api, data, getList, logOut])

  const deleteAction = useCallback(async () => {
    try {
      initialValue.id && (await api.deleteParking({ id: initialValue.id }))
      await getList(true)
    } catch (error: any) {
      if (error.response &&  error.response.status === 401) logOut().then()
    }
  }, [api, getList, initialValue.id, logOut])

  return {
    inProgress,
    setInProgress,
    inputHandler,
    data,
    createOrUpdateAction,
    deleteAction,
    getPartnerList,
    getValidationResult,
    disabled,
    setDirty,
    currentUser,
    checkAbility,
  }
}
