import { useCallback, useEffect, useState } from 'react'
import {
  ListItemInterface,
  OptionItemInterface,
  ParkingInterface,
  ValidationResultInterface,
  ValidationRulesInterface,
} from '../../../../../../common/interfaces/interfaces'
import { getApi } from '../../../../../../api/api'
import { useLogout } from '../../../../../../common/hooks/useLogout'
import { useSelector } from 'react-redux'
import { selectParkingList } from '../../../../../../store/slices/parkingListSlice'
import { useInput } from '../../../../../../common/hooks/useInput'
import { useValidation } from '../../../../../../common/hooks/useValidation'
import { listValidations } from '../../../../../../constants'
import { selectPartnerList } from '../../../../../../store/slices/partnerListSlice'
import { useCurrentUser } from '../../../../../../common/hooks/useCurrentUser'
import { useAbility } from '../../../../../../common/hooks/useAbility'
const validations: ValidationRulesInterface[] = listValidations

export const useForm = (
  initialValue: ListItemInterface,
  getList: (reUsed?: boolean) => void,
) => {
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [data, setData] = useState<ListItemInterface>(initialValue)
  const [api] = useState(() => getApi())
  const { logOut } = useLogout()
  const parkingList = useSelector(selectParkingList)
  const [validationResult, setValidationResult] = useState<
    ValidationResultInterface[] | []
  >([])

  const { currentUser } = useCurrentUser()
  const partnerList = useSelector(selectPartnerList)
  const [disabled, setDisabled] = useState<boolean>(true)

  const {checkAbility} = useAbility()


  useEffect(() => {
    if (!currentUser.super && data.partnerId === '')
      setData((prevState) => ({
        ...prevState,
        partnerId: currentUser.partnerId,
      }))
  }, [currentUser])

  const getPartnerList = () => {
    let arr: OptionItemInterface[] = []
    for (const item of partnerList) {
      let pattern: OptionItemInterface = {
        id: item.id,
        label: item.name,
        name: item.name,
        value: item.id,
      }
      arr.push(pattern)
    }
    return arr
  }

  const getParkingList = () => {
    let arr: OptionItemInterface[] = []
    for (const item of parkingList.filter(el=>el.partnerId === data.partnerId)) {
      let pattern: OptionItemInterface = {
        id: item.id,
        label: item.address,
        name: item.name,
        value: item.id,
      }
      arr.push(pattern)
    }
    return arr
  }

  const { validate, checkAndAddValidation, setDirty } = useValidation(
    setValidationResult,
    validationResult,
    validations,
  )

  const { inputHandler } = useInput(setData)

  useEffect(() => {
    for (var item in data) {
      if (validations.map((el) => el.fieldName).includes(item)) {
        checkAndAddValidation(item)
      }
    }
  }, [checkAndAddValidation, data])

  useEffect(() => {
    for (var item in data) {
      if (validations.map((el) => el.fieldName).includes(item)) {
        validate(item, data[item])
      }
    }
  }, [data])

  useEffect(() => {
    setDisabled(validationResult.filter((el) => el.notValid).length > 0)
  }, [validationResult])



  const createOrUpdateAction = useCallback(async () => {
    try {
      if (data.id !== '') {
        await api.updateBlackListItem(data)
      } else {
        const { id, ...rest } = data
        await api.createBlackListItem({ ...rest })
      }
      await getList(true)
    } catch (error: any) {
      if (error.response &&  error.response.status === 401) logOut().then()
    }
  }, [api, data, getList, logOut])

  const deleteAction = useCallback(async () => {
    try {
      await api.deleteBlackListItem({ id: [initialValue.id] })
      await getList(true)
    } catch (error: any) {
      if (error.response &&  error.response.status === 401) logOut().then()
    }
  }, [api, getList, initialValue.id, logOut])

  const getValidationResult = (fieldName: string) => {
    return validationResult.find((el) => el.name === fieldName)
  }

  return {
    inProgress,
    setInProgress,
    inputHandler,
    data,
    createOrUpdateAction,
    deleteAction,
    getParkingList,
    getValidationResult,
    disabled,
    setDirty,
    getPartnerList,
    currentUser,
    checkAbility
  }
}
