import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  BarrierPassInterface,
  ParkingInterface,
  PaymentInterface,
  SalesListItemInterface,
} from '../../../../common/interfaces/interfaces'
import { getApi } from '../../../../api/api'
import { useLogout } from '../../../../common/hooks/useLogout'

export const usePayment = () => {
  const [sale, setSale] = useState<SalesListItemInterface | null>()
  const [barrierPassIn, setBarrierPassIn] = useState<
    BarrierPassInterface | null | undefined
  >()
  const [barrierPassOut, setBarrierPassOut] = useState<
    BarrierPassInterface | null | undefined
  >()
  const [api] = useState(() => getApi())
  const { logOut } = useLogout()
  const { id } = useParams()

  const getBarrierPass = useCallback(
    async (id: string) => {
      try {
        if (id) {
          return await api.getBarrierPass({ id })
        }
      } catch (error: any) {
        if (error.response &&  error.response.status === 401) logOut().then()
      }
    },
    [api, logOut, id],
  )

  const getPayment = useCallback(async () => {
    try {
      if (id) {
        const result = await api.getSale({ id })
        if (result) {
          setSale(result)
          const passIn = await getBarrierPass(
            result.barrierPassIn.barrierPassId,
          )
          setBarrierPassIn(passIn)
          if (result.barrierPassOut?.barrierPassId) {
            const passOut = await getBarrierPass(
              result.barrierPassOut.barrierPassId,
            )
            setBarrierPassOut(passOut)
          }
        }
      }
    } catch (error: any) {
      if (error.response &&  error.response.status === 401) logOut().then()
    }
  }, [api, logOut, setSale, id])

  useEffect(() => {
    getPayment()
  }, [id])

  // useEffect(() => {
  //   setBarrierLogIn(

  //   )
  //   setBarrierLogOut(

  //   )
  // }, [payment])

  return { sale, barrierPassIn, barrierPassOut }
}
