import { useCallback, useEffect, useState } from 'react'
import {
  Action,
  FormRoleAbility,
  FormRoleInterface,
  FormRoleSubject,
  RoleInterface,
  Subject,
} from '../../../common/interfaces/interfaces'
import { getApi } from '../../../api/api'
import { useLogout } from '../../../common/hooks/useLogout'
import { abilitiesPattern, subjectsPattern } from '../../../constants'
import { useAbility } from '../../../common/hooks/useAbility'

export const useRoles = () => {
  const initialValuePattern: FormRoleInterface = {
    id: '',
    name: '',
    lastChanged: new Date(),
    abilities: abilitiesPattern,
    partnerId: '',
  }

  const [modalActive, setModalActive] = useState(false)
  const [list, setList] = useState<RoleInterface[]>([])
  const [edit, setEdit] = useState(false)
  const [initialValue, setInitialValue] = useState(initialValuePattern)
  const [api] = useState(() => getApi())
  const { logOut } = useLogout()

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  const {checkAbility} = useAbility()

  // Pagination ...
  const [active, setActive] = useState<boolean>(false)
  const [offset, setOffset] = useState(0)
  const [limit] = useState(8)
  const [totalCount, setTotalCount] = useState<number>(0)

  const startEdit = (item: RoleInterface) => {
    const commonInitialValue: FormRoleInterface = {
      ...initialValue,
      abilities: initialValue.abilities.filter(
        (ability: FormRoleAbility) =>
          !item.abilities.map((item) => item.action).includes(ability.value),
      ),
    }
    const abilitiesArray: FormRoleAbility[] = []
    for (const abilityItem of item.abilities) {
      const subjectsArray: FormRoleSubject[] = []
      for (const subjectItem of abilityItem.subjects) {
        subjectsArray.push({
          name: subjectsPattern.find((el) => el.value === subjectItem)?.name,
          value: subjectItem,
          can: true,
        })
      }
      abilitiesArray.push({
        name: abilitiesPattern.find((el) => el.value === abilityItem.action)
          ?.name,
        value: abilityItem.action,
        subjects: [
          ...subjectsArray,
          ...subjectsPattern.filter(
            (el) => !abilityItem.subjects.includes(el.value),
          ),
        ],
      })
    }
    commonInitialValue.id = item.id
    commonInitialValue.lastChanged = item.lastChanged
    commonInitialValue.name = item.name
    commonInitialValue.partnerId = item.partnerId
    commonInitialValue.abilities.push(...abilitiesArray)
    setInitialValue(JSON.parse(JSON.stringify(commonInitialValue)))
    setEdit(true)
    setModalActive(true)
  }

  const startCreate = () => {
    setEdit(false)
    setInitialValue(JSON.parse(JSON.stringify(initialValuePattern)))
    setModalActive(true)
  }

  const getList = useCallback(
    async (reUsed?: boolean) => {
      try {
        if (!reUsed) {
          setList([])
          setLoading(true)
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 700))
        }
        const result = await api.getRolesList({
          _start: offset.toString(),
          _end: (offset + limit).toString(),
          _sort: '_id',
          _order: 'desc',
        })
        setModalActive(false)
        setList(result.data)
        setTotalCount(result.total)
        if (!reUsed) {
          setLoading(false)
          setMessage('Записей нет')
        }
      } catch (error: any) {
        if (error.response &&  error.response.status === 401) logOut().then()
        if (!reUsed) {
          setLoading(false)
          setMessage('Произошла ошибка во время запроса, попробуйте позже')
        }
      }
    },
    [api, logOut, active, limit, offset],
  )

  useEffect(() => {
    getList()
  }, [api, active, offset])

  return {
    list,
    modalActive,
    setModalActive,
    startEdit,
    edit,
    startCreate,
    totalCount,
    limit,
    offset,
    setOffset,
    loading,
    message,
    getList,
    initialValue,
    checkAbility
  }
}
