import { useCallback, useEffect, useState } from 'react'
import {
  RoleInterface,
  UserInterface,
} from '../../../common/interfaces/interfaces'
import { getApi } from '../../../api/api'
import { useLogout } from '../../../common/hooks/useLogout'
import { useAbility } from '../../../common/hooks/useAbility'

export const initialValuePattern: UserInterface = {
  id: '',
  login: '',
  fio: '',
  active: false,
  roleId: '',
  email: '',
  super: false,
  partnerId: '',
  parkingList: [],
}

export const useUsers = () => {
  const [modalActive, setModalActive] = useState<boolean>(false)
  const [list, setList] = useState<UserInterface[]>([])
  const [rolesList, setRolesList] = useState<RoleInterface[]>([])
  const [edit, setEdit] = useState<boolean>(false)

  const { checkAbility } = useAbility()

  const [initialValue, setInitialValue] =
    useState<UserInterface>(initialValuePattern)
  const [api] = useState(() => getApi())
  const { logOut } = useLogout()
  const [active, setActive] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  // Pagination ...
  const [offset, setOffset] = useState(0)
  const [limit] = useState(8)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [activeCount, setActiveCount] = useState<number>(0)

  const startEdit = (item: UserInterface) => {
    setEdit(true)
    setInitialValue(item)
    setModalActive(true)
  }

  const startCreate = () => {
    setEdit(false)
    setInitialValue(initialValuePattern)
    setModalActive(true)
  }

  const getList = useCallback(
    async (reUsed?: boolean) => {
      try {
        if (!reUsed) {
          setList([])
          setLoading(true)
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 700))
        }
        const result = await api.getUserList({
          _start: offset.toString(),
          _end: (offset + limit).toString(),
          _sort: '_id',
          _order: 'desc',
          isActive: active ? active : '',
        })
        setList(result.data)
        setTotalCount(result.total)
        setActiveCount(result.totalActive)
        setModalActive(false)

        if (!reUsed) {
          setLoading(false)
          setMessage('Записей нет')
        }
      } catch (error: any) {
        if (error.response &&  error.response.status === 401) logOut().then()
        setModalActive(false)
        if (!reUsed) {
          setLoading(false)
          setMessage('Произошла ошибка во время запроса, попробуйте позже')
        }
      }
    },
    [api, active, logOut, limit, offset, setList, setTotalCount],
  )

  const getRoles = useCallback(
    async (reUsed?: boolean) => {
      try {    
        const result = await api.getRolesList({})
        setRolesList(result.data)
      } catch (error: any) {
        if (error.response &&  error.response.status === 401) logOut().then()
      }
    },
    [api, logOut],
  )

  useEffect(() => {
    getList()
  }, [api, limit, offset, active])

  useEffect(() => {
    getRoles()
  }, [api])

  const toggleActive = () => {
    setActive((prev) => !prev)
  }

  const getRoleName = (roleId: string) => {
    const role = rolesList.find((el) => el.id === roleId)?.name
    if (role) return role
    return 'Роль не найдена'
  }

  return {
    list,
    modalActive,
    setModalActive,
    edit,
    setEdit,
    initialValue,
    startEdit,
    startCreate,
    getList,
    limit,
    offset,
    setOffset,
    active,
    loading,
    message,
    totalCount,
    toggleActive,
    activeCount,
    checkAbility,
    getRoleName
  }
}
