import axios, { AxiosInstance } from 'axios'

import { store } from '../store/store'
import { setAuthToken } from '../store/slices/authSlice'
import {
  GetOneParams,
  LoginResponse,
  NotificationTypes,
} from '../common/interfaces/interfaces'
import { addNotification } from '../store/slices/notificationSlice'
import { v4 } from 'uuid'

const host = window.location.hostname
const baseUrl =
  host === 'localhost'
    ? 'http://localhost:5001/api/data'
    : `http://${window.location.host}/api/data`

const authUrl =
  host === 'localhost'
    ? 'http://localhost:8000/api/auth'
    : `http://${window.location.host}/api/auth`

let fileApi: FileApi | null = null

class FileApi {
  private readonly axiosInstance: AxiosInstance
  private store = store

  constructor(baseURL: string) {
    this.axiosInstance = axios.create({
      baseURL,
      timeout: 5000,
      responseType: 'blob',
      headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf'
      }
    })
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config
        if (
          originalRequest &&
          error.response &&
          error.response &&  error.response.status === 401 &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true
          const result = await this.refresh()
          if (result) {
            const token = result.token
            this.setToken(token)
            this.store.dispatch(setAuthToken(token))
          }
          return this.axiosInstance(originalRequest)
        }
        return Promise.reject(error)
      },
    )
  }

  setToken(token: string) {
    this.axiosInstance.interceptors.request.use(
      (config) => {
        config.headers.Authorization = store.getState().auth.token
          ? 'Bearer ' + store.getState().auth.token
          : 'Bearer ' + token
        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )
  }

  refresh(): Promise<LoginResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.get<LoginResponse>(
          `${authUrl}/user/refresh`,
          {
            withCredentials: true,
            timeout: 5000,
          },
        )

        resolve(result.data)
      } catch (error: any) {
        reject(error)
      }
    })
  }

  printSubscription(params: GetOneParams): Promise<Blob> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.get<Blob>(
          `/subscription/print/${params.id}`,
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }
}

export const getFileApi = () => {
  if (!fileApi) fileApi = new FileApi(baseUrl)
  return fileApi
}
