import { useCallback, useEffect, useState } from 'react'
import {
  CloseSaleInterface,
  DeviceItemInterface,
  OptionItemInterface,
  PaymentTypes,
  SalesListItemInterface,
  SubscriptionItemInterface,
  ValidationResultInterface,
  ValidationRulesInterface,
} from '../../../../../common/interfaces/interfaces'
import { getApi } from '../../../../../api/api'
import { useLogout } from '../../../../../common/hooks/useLogout'
import { useInput } from '../../../../../common/hooks/useInput'
import { useValidation } from '../../../../../common/hooks/useValidation'
import { closeSaleValidations } from '../../../../../constants'
import { useCurrentUser } from '../../../../../common/hooks/useCurrentUser'

const validations: ValidationRulesInterface[] = closeSaleValidations

export const useForm = (
  initialValue: CloseSaleInterface,
  getList: (reUsed?: boolean) => void,
  current: SalesListItemInterface,
) => {
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [data, setData] = useState<CloseSaleInterface>(initialValue)
  const [subscriptionList, setSubscriptionList] = useState<
    SubscriptionItemInterface[]
  >([])

  const [validationResult, setValidationResult] = useState<
    ValidationResultInterface[] | []
  >([])
  const [api] = useState(() => getApi())
  const { logOut } = useLogout()
  const [disabled, setDisabled] = useState<boolean>(false)
  const { currentUser } = useCurrentUser()

  const { validate, checkAndAddValidation, setDirty } = useValidation(
    setValidationResult,
    validationResult,
    validations,
  )

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      partnerId: current.partnerId,
      saleId: current.id ? current.id : '',
    }))
  }, [current])

  const { inputHandler } = useInput(setData, checkAndAddValidation)

  const getSubscriptionList = useCallback(async () => {
    try {
      const result = await api.getSubscriptionList({
        _start: '0',
        _end: '100',
        _sort: '_id',
        _order: 'desc',
        isActive: true,
        partnerId: current.partnerId,
      })
      setSubscriptionList(result.data)
    } catch (error: any) {
      if (error.response &&  error.response.status === 401) logOut().then()
    }
  }, [api, current.partnerId, logOut])

  useEffect(() => {
    getSubscriptionList()
  }, [api, getSubscriptionList])

  useEffect(() => {
    for (var item in data) {
      if (validations.map((el) => el.fieldName).includes(item)) {
        checkAndAddValidation(item)
      }
    }
  }, [checkAndAddValidation, data])

  useEffect(() => {
    for (var item in data) {
      if (validations.map((el) => el.fieldName).includes(item)) {
        validate(item, data[item])
      }
    }
  }, [data])

  useEffect(() => {
    data.paymentType === PaymentTypes.SUBSCRIPTION.toString()
      ? setDisabled(validationResult.filter((el) => el.notValid).length > 0)
      : setDisabled(
          validationResult.filter(
            (el) => el.notValid && el.name !== 'subscriptionId',
          ).length > 0,
        )
  }, [validationResult, data.paymentType])

  const getValidationResult = (fieldName: string) => {
    return validationResult.find((el) => el.name === fieldName)
  }

  const getSubscriptionsList = () => {
    let arr: OptionItemInterface[] = []
    for (const item of subscriptionList) {
      let pattern: OptionItemInterface = {
        id: item.id,
        label: item.name,
        name: item.name,
        value: item.id,
      }
      arr.push(pattern)
    }
    return arr
  }

  const closeSale = useCallback(async () => {
    try {
      //closeSale route

      const { paymentType, subscriptionId, ...rest } = current
      await api.closeSale({
        paymentType: Number(data.paymentType),
        subscriptionId: data.subscriptionId,
        ...rest,
      })

      getList(true)
    } catch (error: any) {
      if (error.response &&  error.response.status === 401) logOut().then()
    }
  }, [data, api, current, currentUser.id, getList, logOut])

  return {
    inProgress,
    setInProgress,
    inputHandler,
    data,
    checkAndAddValidation,
    getValidationResult,
    disabled,
    setDisabled,
    getSubscriptionsList,
    setDirty,
    closeSale,
  }
}
