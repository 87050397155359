import { useCallback, useEffect, useState } from 'react'

import { SalesReportInterface } from '../../../../common/interfaces/interfaces'
import { getApi } from '../../../../api/api'
import { gteBackTime } from '../../../../constants'
import { useLogout } from '../../../../common/hooks/useLogout'

export const useSales = () => {
  const [api] = useState(() => getApi())
  const [list, setList] = useState<SalesReportInterface>({ data: [] })
  const [gte, setGte] = useState<string>(
    new Date(new Date().getTime() - gteBackTime).toISOString(),
  )
  const [lte, setLte] = useState<string>(
    new Date(new Date().getTime()).toISOString(),
  )
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(
    'для формирования отчета используйте фильтр',
  )
  const [disabled, setDisabled] = useState<boolean>(false)
  const [dateMessage] = useState<string>('Проверьте даты')

  const { logOut } = useLogout()

  const getReport = useCallback(async () => {
    try {
      setList({ data: [] })
      setLoading(true)
      await new Promise<void>((resolve) => setTimeout(() => resolve(), 700))
      const result = await api.getSalesReport({
        _gte: gte,
        _lte: lte,
      })
      setList(result)
      setLoading(false)
      setMessage('Записей нет')
    } catch (error: any) {
      if (error.response &&  error.response.status === 401) logOut().then()
      setLoading(false)
      setMessage('Произошла ошибка во время запроса, попробуйте позже')
    }
  }, [api, lte, gte, logOut])

  const setFrom = (value: string) => {
    setGte(new Date(value).toISOString())
  }
  const setTo = (value: string) => {
    setLte(new Date(new Date(value).setUTCHours(23, 59, 59, 999)).toISOString())
  }

  // useEffect(() => {
  //   getReport()
  // }, [api])

  useEffect(() => {
    setDisabled(new Date(gte).getTime() > new Date(lte).getTime())
  }, [lte, gte])

  return {
    list,
    gte,
    lte,
    setFrom,
    setTo,
    getReport,
    loading,
    message,
    disabled,
    dateMessage,
  }
}
