import { addDays } from 'date-fns'

export const useSubscriptionItem = () => {
  const calculateDaysLeft = (start: Date, validity: number | string) => {
    const result = (
      (Number(
        new Date(addDays(start, typeof validity !== 'string' ? validity : 0)),
      ) -
        Number(new Date())) /
      1000 /
      60 /
      60 /
      24
    ).toFixed(0)
    if (Number(result) > 0) return result
    return 0
  }
  const calculateEndDate = (start: Date, validity: number | string) => {
    return addDays(new Date(start), typeof validity !== 'string' ? validity : 0)
  }

  return { calculateDaysLeft, calculateEndDate }
}
