import { Sales } from './pages/reports/sales/Sales'

import {
  Action,
  Subject,
  ValidationRulesInterface,
} from './common/interfaces/interfaces'

export const ADV_ROUTE = '/adv'
export const MAIN_ROUTE = '/'
export const LOGIN_ROUTE = '/login'
export const USERS_ROUTE = '/users'
export const ROLES_ROUTE = '/roles'
export const SALES_ROUTE = '/sales'
export const BARRIER_LOGS_ROUTE = '/barrier_logs'
export const PARKING_ROUTE = '/parking'
export const SUBSCRIPTIONS_ROUTE = '/subscriptions'
export const SETTINGS_ROUTE = '/settings'
export const WHITE_LIST_ROUTE = '/white_list'
export const BLACK_LIST_ROUTE = '/black_list'
export const PARTNERS_ROUTE = '/partners'
export const SALES_LIST_ROUTE = '/sales_list'
export const SUBSCRIPTION_ITEM_ROUTE = '/pass_item'
export const PAYMENT_ITEM_ROUTE = '/payment_item'
export const BARRIER_PASS_ROUTE = '/barrier_pass_item'
export const LOCAL_DEVICE_ROUTE = '/local_device'

export const usersListLimit = 3

export const gteBackTime = 1000 * 60 * 60 * 24 * 100

export const validLogin = /^/
export const validFio = /^/
export const validInRussian = /^/
export const validItn = /^[0-9]*$/
export const validPassword = /^/
export const validName = /^/
export const validPhone =
  /(^8|7|\+7)((\d{10})|(\s\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}))/
export const validEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const validDeviceId =
  /^[0-9(a-f|A-F)]{8}-[0-9(a-f|A-F)]{4}-4[0-9(a-f|A-F)]{3}-[89ab][0-9(a-f|A-F)]{3}-[0-9(a-f|A-F)]{12}$/
export const validNumber = /^[1-9]\d*$/;

export const userValidations: ValidationRulesInterface[] = [
  {
    fieldName: 'fio',
    minLength: 3,
    maxLength: 60,
    format: validFio,
    canBeEmpty: false,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'login',
    minLength: 3,
    maxLength: 20,
    format: validLogin,
    canBeEmpty: false,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'password',
    minLength: 3,
    maxLength: 20,
    canBeEmpty: true,
    format: validPassword,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'email',
    minLength: 3,
    maxLength: 30,
    canBeEmpty: false,
    format: validEmail,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'parkingList',
    canBeEmpty: false,
    isEmptyMessage: 'Выберите как минимум одну парковку',
    toShortMessage: '',
    tooLongMessage: '',
    formatMessage: '',
  },
  {
    fieldName: 'partnerId',
    canBeEmpty: false,
    isEmptyMessage: 'Выберите партнера',
    toShortMessage: '',
    tooLongMessage: '',
    formatMessage: '',
  },
  {
    fieldName: 'roleId',
    canBeEmpty: false,
    isEmptyMessage: 'Выберите роль',
    toShortMessage: '',
    tooLongMessage: '',
    formatMessage: '',
  },
]

export const barrierValidations: ValidationRulesInterface[] = [
  {
    fieldName: 'partnerId',
    canBeEmpty: false,
    isEmptyMessage: 'Выберите партнера',
    toShortMessage: '',
    tooLongMessage: '',
    formatMessage: '',
  },
  {
    fieldName: 'name',
    minLength: 3,
    maxLength: 60,
    canBeEmpty: false,
    format: validName,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'parkingId',
    canBeEmpty: false,
    isEmptyMessage: 'Выберите парковку',
    toShortMessage: '',
    tooLongMessage: '',
    formatMessage: '',
  },
]
export const roleValidations: ValidationRulesInterface[] = [
  {
    fieldName: 'partnerId',
    canBeEmpty: false,
    isEmptyMessage: 'Выберите партнера',
    toShortMessage: '',
    tooLongMessage: '',
    formatMessage: '',
  },
  {
    fieldName: 'name',
    minLength: 3,
    maxLength: 60,
    canBeEmpty: false,
    format: validName,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
]
export const partnerValidations: ValidationRulesInterface[] = [
  {
    fieldName: 'name',
    minLength: 3,
    maxLength: 30,
    canBeEmpty: false,
    format: validName,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'itn',
    minLength: 3,
    maxLength: 12,
    canBeEmpty: false,
    format: validItn,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'phone',
    minLength: 3,
    maxLength: 12,
    canBeEmpty: false,
    format: validPhone,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'address',
    minLength: 3,
    maxLength: 80,
    canBeEmpty: false,
    format: validName,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'contactPerson',
    minLength: 3,
    maxLength: 50,
    canBeEmpty: false,
    format: validName,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
]

export const parkingValidations: ValidationRulesInterface[] = [
  {
    fieldName: 'name',
    minLength: 3,
    maxLength: 30,
    canBeEmpty: false,
    format: validName,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'address',
    minLength: 3,
    maxLength: 80,
    canBeEmpty: false,
    format: validName,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'partnerId',
    canBeEmpty: false,
    isEmptyMessage: 'Выберите партнера',
    toShortMessage: '',
    tooLongMessage: '',
    formatMessage: '',
  },
  {
    fieldName: 'cost',
    minLength: 1,
    maxLength: 10,
    canBeEmpty: false,
    format: validItn,
    isEmptyMessage: 'Поле не может быть равно 0',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'freeMinutesAfterPayment',
    minLength: 1,
    maxLength: 10,
    canBeEmpty: false,
    format: validItn,
    isEmptyMessage: 'Поле не может быть равно 0',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'capacity',
    minLength: 1,
    maxLength: 10,
    canBeEmpty: false,
    format: validItn,
    isEmptyMessage: 'Поле не может быть равно 0',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
]

export const listValidations: ValidationRulesInterface[] = [
  {
    fieldName: 'carNumber',
    minLength: 3,
    maxLength: 12,
    canBeEmpty: false,
    format: validName,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'comment',
    minLength: 3,
    maxLength: 60,
    canBeEmpty: false,
    format: validName,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'parkingId',
    canBeEmpty: false,
    isEmptyMessage: 'Выберите как минимум одну парковку',
    toShortMessage: '',
    tooLongMessage: '',
    formatMessage: '',
  },
  {
    fieldName: 'partnerId',
    canBeEmpty: false,
    isEmptyMessage: 'Выберите партнера',
    toShortMessage: '',
    tooLongMessage: '',
    formatMessage: '',
  },
]

export const subscriptionValidations: ValidationRulesInterface[] = [
  {
    fieldName: 'parkingId',
    canBeEmpty: false,
    isEmptyMessage: 'Выберите парковку',
    toShortMessage: '',
    tooLongMessage: '',
    formatMessage: '',
  },
  {
    fieldName: 'partnerId',
    canBeEmpty: false,
    isEmptyMessage: 'Выберите партнера',
    toShortMessage: '',
    tooLongMessage: '',
    formatMessage: '',
  },
  {
    fieldName: 'carNumber',
    minLength: 3,
    maxLength: 12,
    canBeEmpty: false,
    format: validName,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'name',
    minLength: 3,
    maxLength: 30,
    canBeEmpty: false,
    format: validName,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'availableMin',
    canBeEmpty: false,
    format: validNumber,
    minLength: 1,
    maxLength: 4,
    isEmptyMessage: 'Поле не может быть равно 0',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'cost',
    minLength: 1,
    maxLength: 10,
    canBeEmpty: false,
    format: validNumber,
    isEmptyMessage: 'Поле не может быть равно 0',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'validity',
    minLength: 1,
    maxLength: 3,
    canBeEmpty: false,
    format: validNumber,
    isEmptyMessage: 'Поле не может быть равно 0',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
]

export const openBarrierDataValidations: ValidationRulesInterface[]  = [
  {
    fieldName: 'carNumber',
    minLength: 1,
    maxLength: 12,
    canBeEmpty: false,
    format: validName,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  }, 
  {
    fieldName: 'deviceId',
    canBeEmpty: false,
    isEmptyMessage: 'Выберите устройство',
    toShortMessage: '',
    tooLongMessage: '',
    formatMessage: '',
  },
]

export const closeSaleValidations: ValidationRulesInterface[] = [

  {
    fieldName: 'paymentType',
    canBeEmpty: false,
    isEmptyMessage: 'Выберите тип оплаты',
    toShortMessage: '',
    tooLongMessage: '',
    formatMessage: '',
  },
  {
    fieldName: 'subscriptionId',
    canBeEmpty: false,
    isEmptyMessage: 'Выберите абонемент',
    toShortMessage: '',
    tooLongMessage: '',
    formatMessage: '',
  },
]

export const loginValidations: ValidationRulesInterface[] = [
  {
    fieldName: 'login',
    canBeEmpty: false,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: '',
    tooLongMessage: '',
    formatMessage: '',
  },
  {
    fieldName: 'password',
    canBeEmpty: false,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: '',
    tooLongMessage: '',
    formatMessage: '',
  },
]

export const subjectsPattern = [
  { name: 'Пользователи', value: Subject.Users, can: false },
  { name: 'Парковки', value: Subject.Parking, can: false },
  { name: 'Шлагбаумы', value: Subject.Barriers, can: false },
  { name: 'Роли', value: Subject.Roles, can: false },
  { name: 'Абонементы', value: Subject.Subscriptions, can: false },
  { name: 'Белый список', value: Subject.WhiteList, can: false },
  { name: 'Черный список', value: Subject.BlackList, can: false },
  { name: 'Журнал шлагбаумов', value: Subject.BarrierPasses, can: false },
  { name: 'Журнал продаж', value: Subject.Sales, can: false },
  { name: 'Отчет по продажам', value: Subject.SalesReport, can: false },
]

export const abilitiesPattern = [
  {
    name: 'Создание',
    value: Action.Create,
    subjects: [...subjectsPattern],
  },
  {
    name: 'Чтение',
    value: Action.Read,
    subjects: [...subjectsPattern],
  },
  {
    name: 'Изменение',
    value: Action.Update,
    subjects: [...subjectsPattern],
  },

  {
    name: 'Удаление',
    value: Action.Delete,
    subjects: [...subjectsPattern],
  },
]

export const deviceValidations: ValidationRulesInterface[] = [
  {
    fieldName: 'name',
    minLength: 3,
    maxLength: 16,
    canBeEmpty: false,
    format: validName,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'deviceId',
    minLength: 36,
    maxLength: 36,
    canBeEmpty: false,
    format: validDeviceId,
    isEmptyMessage: 'Поле не может быть пустым',
    toShortMessage: 'Данные слишком короткие',
    tooLongMessage: 'Данные слишком длинные',
    formatMessage: 'Данные не верного формата',
  },
  {
    fieldName: 'partnerId',
    canBeEmpty: false,
    isEmptyMessage: 'Выберите партнера',
    toShortMessage: '',
    tooLongMessage: '',
    formatMessage: '',
  },
  {
    fieldName: 'parkingId',
    canBeEmpty: false,
    isEmptyMessage: 'Выберите парковку',
    toShortMessage: '',
    tooLongMessage: '',
    formatMessage: '',
  },
]
