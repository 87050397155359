import classes from './UserInfo.module.sass'
import { useUserInfo } from './hooks/useUserInfo'

export const UserInfo = () => {
  const {  currentUser } = useUserInfo()

  return (
    <div className={classes.Container} >
      <div className={classes.AvatarImage}>
        {currentUser.login.slice(0, 1).toUpperCase()}
      </div>
      <div className={classes.InfoBlock}>
        <div className={classes.UserFio}>{currentUser.fio}</div>
        <div className={classes.Role}>
          {currentUser.super ? 'Супер пользователь' : 'Пользователь'}
        </div>    
      </div>
    </div>
  )
}
