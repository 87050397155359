import React from 'react'
import classes from './Shorten.module.sass'
import { useShorten } from './hooks/useShorten'

type Props = {
  id: string
}

export const Shorten = ({ id }: Props) => {
  const { shortenId, copied, copy } = useShorten()

  return (
    <div className={classes.Container}>
      <div
        onClick={() => {
          copy(id)
        }}
      >
        #{shortenId(id)}
      </div>
      <div className={classes.PopupAnchor}>
        <div className={copied ? classes.PopupCopied : classes.PopUp}>
          <div className={classes.PopupInfo}>СКОПИРОВАНО</div>
        </div>
      </div>
    </div>
  )
}
