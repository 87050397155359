import {
  ExtractSubjectType,
  MongoAbility,
  MongoQuery,
  SubjectRawRule,
} from '@casl/ability'

export interface AuthState {
  token: string
}

export enum Action {
  Manage = 'manage',
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}

export enum Subject {
  Users = 'user',
  Roles = 'role',
  BarrierPasses = 'barrierPass',
  Barriers = 'barrier',
  Subscriptions = 'subscription',
  Partners = 'partner',
  Sales = 'sale',
  BlackList = 'blackList',
  WhiteList = 'whiteList',
  Parking = 'parking',
  SalesReport = 'salesReport',
  LocalDevices = 'localDevices',
  All = 'all',
  Skip = 'skip',
}

export type AppAbility = MongoAbility<[Action, Subject]>

export interface LoginPayload {
  login: string
  password: string
}

export interface LoginResponse {
  token: string
  tokenExpiry: number
}

export interface CreateParkingInterface {
  [key: string]:
    | string
    | number
    | boolean
    | undefined
    | Date
    | (string | undefined)[]
  address: string
  name: string
  capacity: number | string
  isHourPrice: boolean
  active: boolean
  cost: number | string
  freeMinutesAfterPayment: number | string
  partnerId: string
}

export interface ParkingInterface extends CreateParkingInterface {
  id: string
}

export interface LoginCredentials {
  [key: string]: string
  login: string
  password: string
}

export interface Device {
  innerId: number
  sensors: Sensor[]
  works: boolean
}

export interface Sensor {
  innerId: number
  active: boolean
}

export interface UserInterface {
  [key: string]:
    | string
    | number
    | boolean
    | undefined
    | Date
    | (string | undefined)[]
  id?: string
  login: string
  password?: string
  fio: string
  roleId: string
  active: boolean
  super: boolean
  parkingList: string[]
  partnerId: string
  email: string
}

export interface UserInterfaceWithAbility {
  id?: string
  login: string
  password?: string
  fio: string
  roleId: string
  active: boolean
  super: boolean
  parkingList: string[]
  partnerId: string
  email: string
  abilities: SubjectRawRule<Action, ExtractSubjectType<Subject>, MongoQuery>[]
}

export interface SuperUserInterface {
  innerId: number
  login: string
  fio: string
  active: boolean
}

export interface RoleInterface {
  id?: string
  name: string
  lastChanged: Date
  abilities: Abilities[]
  partnerId: string
}

export interface Abilities {
  action: Action
  subjects: Subject[]
}
export interface FormRoleInterface {
  [key: string]: string | Date | FormRoleAbility[] | undefined
  id?: string
  name: string
  lastChanged: Date
  abilities: FormRoleAbility[]
  partnerId: string
}

export interface FormRoleAbility {
  name: string | undefined
  value: Action
  subjects: FormRoleSubject[]
}

export interface FormRoleSubject {
  name: string | undefined
  value: Subject
  can: boolean
}

export interface RightInterface {
  id: number
  name: string
  create: boolean
  read: boolean
  update: boolean
  delete: boolean
}

export interface ParkingByUserInterface {
  id: number
  userId: number
  parkingId: number
}

export interface CreateBarrierPassInterface {
  dateTime: Date
  videoUrl: string
  carNumber: string
  partnerId: string
  subscriptionId: string
  parkingId: string
  deviceId: string
  userId?: string
  paymentId: string
}
export interface BarrierPassInterface extends CreateBarrierPassInterface {
  id: string
}

export interface PaymentInterface {
  id: number
  type: 'pass' | 'bankCard'
  docId: number
  amount: number
}

export interface BarrierPassDetailsInterface {
  deviceId: string
  barrierPassId: string
  dateTime: Date | null
}

export interface SalesListItemInterface {
  [key: string]:
    | string
    | number
    | boolean
    | undefined
    | Date
    | BarrierPassDetailsInterface
  id?: string
  cost: number
  paymentType: PaymentTypes
  subscriptionId?: string
  barrierPassIn: BarrierPassDetailsInterface
  barrierPassOut?: BarrierPassDetailsInterface
  parkingId: string
  userId: string
  partnerId: string
}

export enum PaymentTypes {
  CASH = 0,
  CARD = 1,
  SUBSCRIPTION = 2,
}

export interface SalesReportItemInterface {
  parkingId: string
  data: PeriodInterface[] | []
}

export interface PeriodInterface {
  id?: number
  period: string
  exits: number
  hours: number
  amount: number
}

export interface SalesReportInterface {
  data: SalesReportItemInterface[]
}

export interface SalesReportForDashboardResponse {
  data: SalesReportForDashboardItemInterface[]
}

export interface SalesReportForDashboardItemInterface {
  parkingId: string
  day: number
  month: number
}


export interface SalesReportItemInterface {
  parkingId: string
  data: PeriodInterface[] | []
}


export interface SubscriptionItemInterface {
  [key: string]:
    | string
    | number
    | boolean
    | undefined
    | Date
    | (string | undefined)[]
  id?: string
  carNumber: string
  name: string
  saleDate: Date
  expireDate?: Date
  validity: number | string
  availableMin: number | string
  placeReserve: boolean
  active: boolean
  cost: number | string
  partnerId: string
  parkingId: string
}



export interface ListItemInterface {
  [key: string]:
    | string
    | number
    | boolean
    | undefined
    | Date
    | (string | undefined)[]
  id?: string
  carNumber: string
  comment: string
  service?: boolean
  active: boolean
  parkingId: string
  partnerId: string
}

export interface GroupItemInterface {
  id: number
  name: string
}

export interface PartnerInterface {
  [key: string]:
    | string
    | number
    | boolean
    | undefined
    | Date
    | (string | undefined)[]
  id?: string
  name: string
  itn: string
  phone: string
  address: string
  contactPerson: string
  active: boolean
}

export interface OptionItemInterface {
  id: string | undefined
  name: string
  label: string
  hidden?: boolean
  selected?: boolean
  value?: string
}

export interface CommonParams {
  _start: string
  _end: string
  _sort: string
  _order: string
}

export interface ParkingListParams extends CommonParams {
  isActive: boolean | string
  partnerId?: (string | undefined)[]
}

export interface SalesReportParams {
  _gte: string
  _lte: string
}

export interface SalesReportForDashboardParams {
  _gte: string
  _lte: string
  parkingId: string[]
}

export interface SubscriptionListParams extends CommonParams {
  isActive: boolean | string
  partnerId?: string
}
export interface ListParams extends CommonParams {
  isActive: string | boolean
  partnerId?: string
}

export interface BarrierPassesListParams extends CommonParams {
  partnerId?: string
  _gte: string
  _lte: string
}
export interface RolesListParams {
  _start?: string
  _end?: string
  _sort?: string
  _order?: string
}
export interface SalesListParams extends CommonParams {
  partnerId?: string
  _gte: string
  _lte: string
}
export interface PartnerListParams {
  _start?: string
  _end?: string
  _sort?: string
  _order?: string
  isActive?: string | boolean
}
export interface DeviceListParams {
  _start?: string
  _end?: string
  _sort?: string
  _order?: string
  isActive?: string | boolean
  parkingId?: string | string[]
}
export interface UserListParams extends CommonParams {
  isActive: string | boolean
  partnerId?: string
}

export interface GetOneParams {
  id: string
}

export interface ParkingListResponse {
  data: ParkingInterface[]
  total: number
  totalActive: number
}
export interface ParkingList {
  data: ParkingInterface[]
}
export interface PartnerList {
  data: PartnerInterface[]
}

export interface SubscriptionListResponse {
  data: SubscriptionItemInterface[]
  total: number
  totalActive: number
}
export interface ListResponse {
  data: ListItemInterface[]
  total: number
  totalActive: number
}

export interface BarrierPassesListResponse {
  data: BarrierPassInterface[]
  total: number
}
export interface RolesListResponse {
  data: RoleInterface[]
  total: number
}

export interface SalesListResponse {
  data: SalesListItemInterface[]
  total: number
}
export interface PartnerListResponse {
  data: PartnerInterface[]
  total: number
  totalActive: number
}
export interface DeviceListResponse {
  data: DeviceItemInterface[]
  total: number
  totalActive?: number
}
export interface UserListResponse {
  data: UserInterface[]
  total: number
  totalActive: number
}

export interface DeleteParams {
  id: string | undefined | (string | undefined)[]
}

export interface ValidationsInterface {
  isEmpty?: boolean
  minLength?: number
  maxLength?: number
  validFormat?: RegExp
}

export interface ValidationResultInterface {
  [key: string]: string | boolean
  name: string
  isDirty: boolean
  isEmpty: boolean
  notValidFormat: boolean
  tooShort: boolean
  tooLong: boolean
  isEmptyError: boolean
  notValid: boolean
  errorMessage: string
}

export interface ValidationRulesInterface {
  fieldName: string
  minLength?: number
  maxLength?: number
  format?: RegExp | ''
  canBeEmpty?: boolean
  isEmptyMessage: string
  toShortMessage: string
  tooLongMessage: string
  formatMessage: string
}

export enum NotificationTypes {
  SUCCESS,
  ERROR,
}

export interface NotificationInterface {
  id: string
  type: NotificationTypes
  text: string
}
export interface NotificationList {
  data: NotificationInterface[]
}

export interface CloseSaleInterface {
  [key: string]:
    | string
    | number
    | boolean
    | undefined
    | Date
    | (string | undefined)[]
  paymentType: PaymentTypes | ''
  dateTime: Date
  subscriptionId: string
  saleId: string
  partnerId: string
}

export interface DeviceItemInterface {
  [key: string]: string | boolean | undefined
  id?: string
  deviceId: string
  name: string
  parkingId: string
  partnerId: string
  isActive: boolean
}

export interface DeviceState {
  deviceId: string
  deviceName: string
  clientId: string
  parentDeviceId?: string
}

export interface DeviceStateList {
  data: DeviceState[]
}

export interface ClientDisconnectedEventData {
  clientId: string
}

export interface OpenBarrierData {
  [key: string]: string
  deviceId: string
  carNumber: string
}

export interface OpenBarrierPayload {
  deviceId: string
  way: Way
  saleId?: string
  carNumber: string
  parkingId?: string
}

export enum Way {
  'in',
  'out',
}

export interface PlacesResponse {
  total: number
  inUse: number
}

