import { useCallback, useState } from 'react'
import { useLogout } from '../../../../../../common/hooks/useLogout'
import { getApi } from '../../../../../../api/api'
import { ListItemInterface } from '../../../../../../common/interfaces/interfaces'
import { useAbility } from '../../../../../../common/hooks/useAbility'

export const useBlackListItem = (
  listItem: ListItemInterface,
  getList: ((reUsed: boolean) => Promise<void>) | undefined,
) => {
  const [api] = useState(() => getApi())
  const { logOut } = useLogout()
  const [data, setData] = useState<ListItemInterface>(listItem)

  const { checkAbility } = useAbility()

  const updateAction = useCallback(async () => {
    try {
      if (getList) {
        await api.updateBlackListItem(data)
        await getList(true)
      }
    } catch (error: any) {
      if (error.response &&  error.response.status === 401) logOut().then()
    }
  }, [api, data, getList, logOut])

  const updateStatus = (newStatus?: boolean) => {
    if (newStatus) setData((prev) => ({ ...prev, active: newStatus }))
    updateAction()
  }

  return { updateStatus, checkAbility }
}
