import { format } from 'date-fns'
import { CarSign } from '../carSign/CarSign'
import classes from './Video.module.sass'
import { BarrierPassInterface } from '../../interfaces/interfaces'
import { Shorten } from '../shorten/Shorten'

type Props = {
  barrierLog?: BarrierPassInterface
  onPage?: boolean
}

export const Video = ({ barrierLog, onPage }: Props) => {
  return (
    <div
      className={onPage ? classes.ContainerOnPage : classes.Container}
      onClick={(e) => e.stopPropagation()}
    >
      {barrierLog && (
        <div
          className={!onPage ? classes.InContainer : classes.InContainerOnPage}
        >
          {!onPage && <div className={classes.Title}>Просмотр видео</div>}
          {onPage && <div className={classes.TitleOnPage}>Видео выезда</div>}
          {!onPage && (
            <div className={classes.DataContainer}>
              <div className={classes.Id}>
                <Shorten id={barrierLog.id} />
              </div>
              <div className={classes.DateTime}>
                <span className={classes.DateTitle}>Дата:</span>{' '}
                <div className={classes.DateTimeWrapper}>
                  {format(new Date(barrierLog.dateTime), 'dd.MM.yyyy')}
                  <div className={classes.Time}>
                    {format(new Date(barrierLog.dateTime), 'HH:mm')}
                  </div>
                </div>
              </div>
              {barrierLog.carNumber && (
                <CarSign number={barrierLog.carNumber} />
              )}
            </div>
          )}
          <div className={!onPage ? classes.Video : classes.VideoOnPage}>
          </div>
        </div>
      )}
    </div>
  )
}
