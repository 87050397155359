import { useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import {
  OptionItemInterface,
  ParkingInterface,
  RoleInterface,
  UserInterface,
  ValidationResultInterface,
} from '../../../../../common/interfaces/interfaces'
import { getApi } from '../../../../../api/api'
import { useLogout } from '../../../../../common/hooks/useLogout'
import { selectParkingList } from '../../../../../store/slices/parkingListSlice'
import { useInput } from '../../../../../common/hooks/useInput'
import { useValidation } from '../../../../../common/hooks/useValidation'
import { userValidations } from '../../../../../constants'
import { selectPartnerList } from '../../../../../store/slices/partnerListSlice'
import { useCurrentUser } from '../../../../../common/hooks/useCurrentUser'
import { useAbility } from '../../../../../common/hooks/useAbility'

const validations = userValidations

export const useForm = (
  initialValue: UserInterface,
  getList: (reused?: boolean) => void,
) => {
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [data, setData] = useState<UserInterface>(initialValue)
  const [validationResult, setValidationResult] = useState<
    ValidationResultInterface[] | []
  >([])
  const parkingList = useSelector(selectParkingList)
  const [filteredParkingList, setFilteredParkingList] = useState<
    ParkingInterface[]
  >(parkingList.filter((el) => initialValue.parkingList.includes(el.id)))
  const [api] = useState(() => getApi())
  const { logOut } = useLogout()
  const [disabled, setDisabled] = useState<boolean>(true)
  const [rolesList, setRolesList] = useState<RoleInterface[]>([])
  const { checkAbility } = useAbility()

  const { currentUser } = useCurrentUser()

  const partnerList = useSelector(selectPartnerList)

  const getPartnerList = () => {
    let arr: OptionItemInterface[] = []
    for (const item of partnerList) {
      let pattern: OptionItemInterface = {
        id: item.id,
        label: item.name,
        name: item.name,
        value: item.id,
      }
      arr.push(pattern)
    }
    return arr
  }

  useEffect(() => {
    getRolesList()
  }, [api])

  const getRolesListForSelect = () => {
    let arr: OptionItemInterface[] = []
    for (const item of rolesList) {
      let pattern: OptionItemInterface = {
        id: item.id,
        label: item.name,
        name: item.name,
        value: item.id,
      }
      arr.push(pattern)
    }
    return arr
  }

  useEffect(() => {
    if (!currentUser.super && data.partnerId === '')
      setData((prevState) => ({
        ...prevState,
        partnerId: currentUser.partnerId,
      }))
  }, [currentUser])

  useEffect(() => {
    setFilteredParkingList(
      parkingList.filter((el) => el.partnerId === data.partnerId),
    )
  }, [data.partnerId, parkingList])

  const { validate, checkAndAddValidation, setDirty } = useValidation(
    setValidationResult,
    validationResult,
    validations,
  )

  const { inputHandler } = useInput(setData, checkAndAddValidation)

  const getRolesList = useCallback(async () => {
    try {
      const result = await api.getRolesList({})

      setRolesList(result.data)
    } catch (error: any) {
      if (error.response &&  error.response.status === 401) logOut().then()
    }
  }, [api, logOut])

  const createOrUpdateAction = useCallback(async () => {
    try {
      if (data.id !== '') {
        const { id, password, email, ...rest } = data
        password === ''
          ? await api.updateUser({ id, email: email.toLowerCase(), ...rest })
          : await api.updateUser(data)
      } else {
        const { id, password, email, ...rest } = data
        await api.createUser({ password, email: email.toLowerCase(), ...rest })
      }
      await getList(true)
    } catch (error: any) {
      if (error.response &&  error.response.status === 401) logOut().then()
    }
  }, [api, data, getList, logOut])

  const deleteAction = useCallback(async () => {
    try {
      initialValue.id && (await api.deleteUser({ id: initialValue.id }))
      await getList(true)
    } catch (error: any) {
      if (error.response &&  error.response.status === 401) logOut().then()
    }
  }, [api, getList, initialValue.id, logOut])

  const getValidationResult = (fieldName: string) => {
    return validationResult.find((el) => el.name === fieldName)
  }

  useEffect(() => {
    for (var item in data) {
      if (validations.map((el) => el.fieldName).includes(item)) {
        checkAndAddValidation(item)
      }
    }
  }, [checkAndAddValidation, data])

  useEffect(() => {
    for (var item in data) {
      if (validations.map((el) => el.fieldName).includes(item)) {
        validate(item, data[item])
      }
    }
    if (data.super) {
      data.partnerId !== '' && inputHandler('', 'partnerId', 'string')
      data.parkingList.length !== 0 && inputHandler([], 'parkingList', 'string')
    }
  }, [data])

  useEffect(() => {
    !data.super
      ? setDisabled(validationResult.filter((el) => el.notValid).length > 0)
      : setDisabled(
          validationResult.filter(
            (el) =>
              el.name !== 'partnerId' &&
              el.name !== 'parkingList' &&
              el.name !== 'roleId' &&
              el.notValid,
          ).length > 0,
        )
  }, [validationResult, data.super])

  return {
    inProgress,
    setInProgress,
    inputHandler,
    data,
    createOrUpdateAction,
    deleteAction,
    filteredParkingList,
    checkAndAddValidation,
    getValidationResult,
    disabled,
    setDisabled,
    setDirty,
    getPartnerList,
    currentUser,
    getRolesListForSelect,
    getRolesList,
    checkAbility,
  }
}
