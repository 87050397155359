import { useEffect, useState } from 'react'

export const useShorten = () => {
  const [copied, setCopied] = useState(false)
  const [timeOut] = useState<{
    value: ReturnType<typeof setTimeout> | undefined
  }>({
    value: undefined,
  })

  const copy = (id: string) => {
    {
      navigator.clipboard.writeText(id)
    }
    setCopied(true)
    timeOut.value = setTimeout(() => {
      clearTimeout(timeOut.value)
      setCopied(false)
    }, 1000)
  }

  const shortenId = (id: string) => {
    const _id = id
    return `${id.slice(0, 3)}...${_id.slice(_id.length - 3, _id.length)}`
  }

  useEffect(() => {
    return () => clearTimeout(timeOut.value)
  }, [])

  return { copied, copy, shortenId }
}
