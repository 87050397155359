import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  BarrierPassInterface,
  ParkingInterface,
  PaymentInterface,
  SubscriptionItemInterface,
} from '../../../../common/interfaces/interfaces'
import { getApi } from '../../../../api/api'
import { useLogout } from '../../../../common/hooks/useLogout'

export const useSubscription = () => {
  const [api] = useState(() => getApi())
  const { logOut } = useLogout()
  const { id } = useParams()

  const [subscription, setSubscription] = useState<
    SubscriptionItemInterface | undefined
  >()

  const getSubscription = useCallback(async () => {
    try {
      if (id) {
        const result = await api.getSubscription({ id })
        if (result) {
          setSubscription(result)
        }
      }
    } catch (error: any) {
      if (error.response &&  error.response.status === 401) logOut().then()
    }
  }, [api, logOut, setSubscription, id])

  useEffect(() => {
    getSubscription()
  }, [id])

  return { subscription }
}
