import { useCallback, useEffect, useState } from 'react'
import { useLogout } from '../../../../../common/hooks/useLogout'
import { getApi } from '../../../../../api/api'
import { PlacesResponse } from '../../../../../common/interfaces/interfaces'

export const useDashBoardItem = (id: string) => {
  const [firstSide, setFirstSide] = useState<boolean>(true)
  const [places, setPlaces] = useState<PlacesResponse>()
  const { logOut } = useLogout()
  const [api] = useState(() => getApi())

  const sideHandler = () => {
    setFirstSide((prev) => !prev)
  }

  const getPlaces = useCallback(async () => {
    try {
      const result = await api.getPlaces({ id })
      result && setPlaces(result)
    } catch (error: any) {
      if (error.response && error.response.status === 401) logOut().then()
    }
  }, [api, id])

  useEffect(()=>{
    getPlaces()
  },[api])

  return { firstSide, sideHandler, places }
}
