import { useEffect, useState } from 'react'
import { menuLinks } from '../constants'
import { useAbility } from '../../../hooks/useAbility'
import { Action, Subject } from '../../../interfaces/interfaces'
import { useLocation } from 'react-router-dom'
import { useLogout } from '../../../hooks/useLogout'

export const useLeftSideMenu = () => {
  const [activeItem, setActiveItem] = useState<number>(0)

  const toggle = (id: number) => {
    setActiveItem((prev) => (prev === id ? 0 : id))
  }

    const { logOut } = useLogout()

  return { activeItem, toggle, logOut }
}
