import React from 'react'
import classes from './DashBoardItem.module.sass'
import {
  DeviceItemInterface,
  ParkingInterface,
  SalesReportForDashboardItemInterface,
  Way,
} from '../../../../common/interfaces/interfaces'
import nav from './../../../../assets/images/icons/nav.svg'
import { Status } from '../../../../common/components/status/Status'
import { Side1 } from './components/side1/Side1'
import { Side2 } from './components/side2/Side2'
import { useDashBoardItem } from './hooks/useDashBoardItem'
import { Shorten } from '../../../../common/components/shorten/Shorten'
import online from '../../../../assets/images/icons/online.svg'
import offline from '../../../../assets/images/icons/offline.svg'

type Props = {
  parking: ParkingInterface
  setCurrentParking: React.Dispatch<
    React.SetStateAction<ParkingInterface | undefined>
  >
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>
  checkDeviceActive: (id: string) => boolean
  startOpenBarrier: (deviceId: string, parkingId: string, way: Way) => void
  getDeviceListByParkingId: (parkingId: string) => DeviceItemInterface[]
  getParkingRevenue: (id: string) => SalesReportForDashboardItemInterface
  startActivateParking: (currentParking: ParkingInterface) => void
  getMonthName: () => string
}

export const DashBoardItem = ({
  parking,
  checkDeviceActive,
  startOpenBarrier,
  getDeviceListByParkingId,
  getParkingRevenue,
  startActivateParking,
  getMonthName,
}: Props) => {
  const { firstSide, places } = useDashBoardItem(parking.id)

  return (
    <div className={classes.Container}>
      <div className={classes.Header}>
        <div className={classes.ParkingInfo}>
          <div className={classes.InParkingInfoWrapper}>
            <img src="" />
            <div
              className={
                checkDeviceActive(parking.id)
                  ? classes.StatusBlockOnline
                  : classes.StatusBlock
              }
            >
              <img
                className={classes.StatusImage}
                src={
                  parking.id && checkDeviceActive(parking.id) ? online : offline
                }
              />
              <div>
                {parking.id && checkDeviceActive(parking.id)
                  ? 'Online'
                  : 'Offline'}
              </div>
            </div>
            <div className={classes.ParkingName}>
              <Shorten id={parking.id} />
              <Status
                mainClick
                pointer
                fixedWith="100px"
                status={parking.active}
                title={{
                  positive: 'Активна',
                  negative: 'Не активна',
                }}
                active_props={parking.active}
                onClick={() => {
                  startActivateParking(parking)
                }}
              />
            </div>
            <div className={classes.ParkingAddress}>
              <img src={nav} alt="Адрес парковки" />
              {parking.address}
            </div>
          </div>
        </div>
      </div>

      {firstSide ? (
        <Side1
          startOpenBarrier={startOpenBarrier}
          parking={parking}
          getDeviceListByParkingId={getDeviceListByParkingId}
          checkDeviceActive={checkDeviceActive}
          getParkingRevenue={getParkingRevenue}
          getMonthName={getMonthName}
          places = {places}
        />
      ) : (
        <Side2 parking={parking} />
      )}

      <div className={classes.Footer}>
        {/* <div className={classes.InFooterWrapper}>
          <div className={classes.InFooterWrapper2} onClick={sideHandler}>
            <img
              src={firstSide ? gauge : warningCircle}
              alt="Обозначение перехода на другую сторону карточки парковки"
            />
            {firstSide ? 'Состояние устройств' : 'Общая информация'}
          </div>
          <img
            className={classes.DevicesIcon}
            src={caret_right}
            alt="Перейти"
          />
        </div> */}
      </div>
    </div>
  )
}
