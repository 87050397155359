import axios, { AxiosInstance } from 'axios'

import { store } from '../store/store'
import { setAuthToken } from '../store/slices/authSlice'
import {
  BarrierPassesListParams,
  BarrierPassesListResponse,
  BarrierPassInterface,
  CreateBarrierPassInterface,
  CreateParkingInterface,
  DeleteParams,
  DeviceItemInterface,
  DeviceListParams,
  DeviceListResponse,
  GetOneParams,
  ListItemInterface,
  ListParams,
  ListResponse,
  LoginPayload,
  LoginResponse,
  NotificationTypes,
  OpenBarrierPayload,
  ParkingInterface,
  ParkingListParams,
  ParkingListResponse,
  PartnerInterface,
  PartnerListParams,
  PartnerListResponse,
  RoleInterface,
  RolesListParams,
  RolesListResponse,
  SalesReportForDashboardParams,
  SalesListItemInterface,
  SalesListParams,
  SalesListResponse,
  SalesReportInterface,
  SalesReportParams,
  SubscriptionItemInterface,
  SubscriptionListParams,
  SubscriptionListResponse,
  UserInterface,
  UserListParams,
  UserListResponse,
  SalesReportForDashboardResponse,
  PlacesResponse,
} from '../common/interfaces/interfaces'
import {
  addNotification,
  removeAllNotifications,
} from '../store/slices/notificationSlice'
import { v4 } from 'uuid'

const host = window.location.hostname
const baseUrl =
  host === 'localhost'
    ? 'http://localhost:5001/api/data'
    : `https://${window.location.host}/api/data`

const authUrl =
  host === 'localhost'
    ? 'http://localhost:8000/api/auth'
    : `https://${window.location.host}/api/auth`

let api: Api | null = null

class Api {
  private readonly axiosInstance: AxiosInstance
  private store = store

  constructor(baseURL: string) {
    this.axiosInstance = axios.create({ baseURL, timeout: 5000, withCredentials: true })
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config
        if (
          originalRequest &&
          error.response &&
          error.response &&  error.response.status === 401 &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true
          const result = await this.refresh()
          if (result) {
            const token = result.token
            this.setToken(token)
            this.store.dispatch(setAuthToken(token))
          }
          return this.axiosInstance(originalRequest)
        }
        return Promise.reject(error)
      },
    )
  }

  setToken(token: string) {
    this.axiosInstance.interceptors.request.use(
      (config) => {
        config.headers.Authorization = store.getState().auth.token
          ? 'Bearer ' + store.getState().auth.token
          : 'Bearer ' + token
        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )
  }

  login(payload: LoginPayload): Promise<LoginResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.post<LoginResponse>(
          `${authUrl}/user/login`,
          payload,
          { withCredentials: true },
        )
        this.store.dispatch(removeAllNotifications())
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.SUCCESS,
            text: 'Вы авторизованы',
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }

  refresh(): Promise<LoginResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.get<LoginResponse>(
          `${authUrl}/user/refresh`,
          {
            withCredentials: true,
            timeout: 5000,
          },
        )

        resolve(result.data)
      } catch (error: any) {
        reject(error)
      }
    })
  }

  logout(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.get<string>(`${authUrl}/user/logout`, {
          withCredentials: true,
          timeout: 5000,
        })
        this.store.dispatch(removeAllNotifications())
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text: 'Вы не авторизованы. При необходимости, авторизуйтесь повторно.',
          }),
        )
        resolve()
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text: 'Вы не авторизованы. При необходимости, авторизуйтесь повторно.',
          }),
        )
        reject(error)
      }
    })
  }

  createSubscription(
    payload: SubscriptionItemInterface,
  ): Promise<SubscriptionItemInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.post(
          '/subscription/user',
          payload,
        )
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.SUCCESS,
            text: `Вы создали абонемент`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }
  updateSubscription(
    payload: SubscriptionItemInterface,
  ): Promise<SubscriptionItemInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const { id, ...rest } = payload
        const result = await this.axiosInstance.put(
          `/subscription/user/${id}`,
          rest,
        )
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.SUCCESS,
            text: `Вы отредактировали абонемент`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }
  getSubscriptionList(
    params: SubscriptionListParams,
  ): Promise<SubscriptionListResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.get<SubscriptionListResponse>(
          '/subscription',
          { params },
        )
        resolve(result.data)
      } catch (error: any) {
        reject(error)
      }
    })
  }

  deleteSubscription(params: DeleteParams): Promise<SubscriptionItemInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const result =
          await this.axiosInstance.delete<SubscriptionItemInterface>(
            `/subscription/${params.id}`,
          )
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text: `Вы удалили абонемент`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }
  // getSubscription() {}

  createParking(payload: CreateParkingInterface): Promise<ParkingInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.post('/parking', payload)
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.SUCCESS,
            text: `Вы создали парковку`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }
  getParking() {}

  getParkingList(params: ParkingListParams): Promise<ParkingListResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.get<ParkingListResponse>(
          '/parking',
          { params },
        )
        resolve(result.data)
      } catch (error: any) {
        reject(error)
      }
    })
  }

  updateParking(payload: ParkingInterface): Promise<ParkingInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const { id, ...rest } = payload
        const result = await this.axiosInstance.put(`/parking/${id}`, rest)
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.SUCCESS,
            text: `Вы отредактировали парковку`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }

  deleteParking(params: DeleteParams): Promise<ParkingInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.delete<ParkingInterface>(
          `/parking/${params.id}`,
        )
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text: `Вы удалили парковку`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }

  createPartner(payload: PartnerInterface): Promise<PartnerInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.post('/partner', payload)
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.SUCCESS,
            text: `Вы создали партнера`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }
  // getPartner() {}
  getPartnerList(params: PartnerListParams): Promise<PartnerListResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.get<PartnerListResponse>(
          '/partner',
          { params },
        )
        resolve(result.data)
      } catch (error) {
        reject(error)
      }
    })
  }
  updatePartner(payload: PartnerInterface): Promise<PartnerInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const { id, ...rest } = payload
        const result = await this.axiosInstance.put(`/partner/${id}`, rest)
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.SUCCESS,
            text: `Вы отредактировали партнера`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }
  deletePartner(params: DeleteParams): Promise<PartnerInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.delete<PartnerInterface>(
          `/partner/${params.id}`,
        )
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text: `Вы удалили партнера`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }

  createUser(payload: UserInterface): Promise<UserInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.post('/user', payload)
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.SUCCESS,
            text: `Вы создали пользователя`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }
  getUser() {}

  getUserList(params: UserListParams): Promise<UserListResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.get<UserListResponse>('/user', {
          params,
        })
        resolve(result.data)
      } catch (error: any) {
        reject(error)
      }
    })
  }
  updateUser(payload: UserInterface): Promise<UserInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const { id, ...rest } = payload
        const result = await this.axiosInstance.put(`/user/${id}`, rest)
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.SUCCESS,
            text: `Вы отредактировали пользователя`,
          }),
        )

        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }
  deleteUser(params: DeleteParams): Promise<UserInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.delete<UserInterface>(
          `/user/${params.id}`,
        )
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text: `Вы удалили пользователя`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }

  createBlackListItem(payload: ListItemInterface): Promise<ListItemInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.post('/black-list', payload)
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.SUCCESS,
            text: `Вы создали запись черного списка`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }
  getBlackList(params: ListParams): Promise<ListResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.get<ListResponse>(
          '/black-list',
          { params },
        )
        resolve(result.data)
      } catch (error) {
        reject(error)
      }
    })
  }
  updateBlackListItem(payload: ListItemInterface): Promise<ListItemInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const { id, ...rest } = payload
        const result = await this.axiosInstance.put(`/black-list/${id}`, rest)
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.SUCCESS,
            text: `Вы отредактировали запись черного списка`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }
  deleteBlackListItem(params: DeleteParams): Promise<ListItemInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.delete<ListItemInterface>(
          `/black-list`,
          { params },
        )
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text: `Вы удадили запись черного списка`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }
  // getBlackListItem() {}

  createWhiteListItem(payload: ListItemInterface): Promise<ListItemInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.post('/white-list', payload)
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.SUCCESS,
            text: `Вы создали запись белого списка`,
          }),
        )
        resolve(result.data)
      } catch (error) {
        reject(error)
      }
    })
  }
  getWhiteList(params: ListParams): Promise<ListResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.get<ListResponse>(
          '/white-list',
          { params },
        )
        resolve(result.data)
      } catch (error) {
        reject(error)
      }
    })
  }
  updateWhiteListItem(payload: ListItemInterface): Promise<ListItemInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const { id, ...rest } = payload
        const result = await this.axiosInstance.put(`/white-list/${id}`, rest)
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.SUCCESS,
            text: `Вы отредактировали запись белого списка`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }
  deleteWhiteListItem(params: DeleteParams): Promise<ListItemInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.delete<ListItemInterface>(
          `/white-list`,
          { params },
        )
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text: `Вы удалили запись белого списка`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }
  // getWhiteListItem() {}

  getBarrierPassesList(
    params: BarrierPassesListParams,
  ): Promise<BarrierPassesListResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.get<BarrierPassesListResponse>(
          '/barrier-pass',
          { params },
        )
        resolve(result.data)
      } catch (error) {
        reject(error)
      }
    })
  }

  getSalesList(params: SalesListParams): Promise<SalesListResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.get<SalesListResponse>(
          '/sale',
          { params },
        )
        resolve(result.data)
      } catch (error) {
        reject(error)
      }
    })
  }

  createBarrierPass(
    payload: CreateBarrierPassInterface,
  ): Promise<BarrierPassInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.post(
          '/barrier-pass/user',
          payload,
        )
        resolve(result.data)
      } catch (error: any) {
        reject(error)
      }
    })
  }

  updateSale(payload: SalesListItemInterface): Promise<SalesListItemInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const { id, ...rest } = payload
        const result = await this.axiosInstance.put(`/sale/user/${id}`, rest)
        resolve(result.data)
      } catch (error: any) {
        reject(error)
      }
    })
  }

  closeSale(payload: SalesListItemInterface): Promise<SalesListItemInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const { id, ...rest } = payload
        const result = await this.axiosInstance.put(`/sale/close/${id}`, rest)
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.SUCCESS,
            text: `Вы закрыли продажу`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }

  getSalesReport(params: SalesReportParams): Promise<SalesReportInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.get<SalesReportInterface>(
          'sale/salesReport',
          { params },
        )
        resolve(result.data)
      } catch (error) {
        reject(error)
      }
    })
  }

  getClosedSalesReportForDashboard(
    params: SalesReportForDashboardParams,
  ): Promise<SalesReportForDashboardResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const result =
          await this.axiosInstance.get<SalesReportForDashboardResponse>(
            'sale/salesForDashBoardReport',
            { params },
          )
        resolve(result.data)
      } catch (error) {
        reject(error)
      }
    })
  }

  getSale(params: GetOneParams): Promise<SalesListItemInterface | null> {
    return new Promise(async (resolve, reject) => {
      try {
        const result =
          await this.axiosInstance.get<SalesListItemInterface | null>(
            `/sale/find/${params.id}`,
          )
        resolve(result.data)
      } catch (error) {
        reject(error)
      }
    })
  }

  getBarrierPass(params: GetOneParams): Promise<BarrierPassInterface | null> {
    return new Promise(async (resolve, reject) => {
      try {
        const result =
          await this.axiosInstance.get<BarrierPassInterface | null>(
            `/barrier-pass/find/${params.id}`,
          )
        resolve(result.data)
      } catch (error) {
        reject(error)
      }
    })
  }

  getSubscription(
    params: GetOneParams,
  ): Promise<SubscriptionItemInterface | null> {
    return new Promise(async (resolve, reject) => {
      try {
        const result =
          await this.axiosInstance.get<SubscriptionItemInterface | null>(
            `/subscription/find/${params.id}`,
          )
        resolve(result.data)
      } catch (error) {
        reject(error)
      }
    })
  }

  // downloadVouchersReportToExcel(params: VouchersReportParams): Promise<Blob> {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const result = await this.axiosInstance.get<Blob>(
  //         'change-check/commonReportToExcel',
  //         { params },
  //       )
  //       resolve(result.data)
  //     } catch (error) {
  //       reject(error)
  //     }
  //   })
  // }

  getRolesList(params: RolesListParams): Promise<RolesListResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.get<RolesListResponse>(
          '/role',
          { params },
        )
        resolve(result.data)
      } catch (error) {
        reject(error)
      }
    })
  }

  getRole(params: GetOneParams): Promise<RoleInterface | null> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.get<RoleInterface | null>(
          `/role/find/${params.id}`,
        )
        resolve(result.data)
      } catch (error) {
        reject(error)
      }
    })
  }

  createRole(payload: RoleInterface): Promise<RoleInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.post('/role', payload)
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.SUCCESS,
            text: `Вы создали роль`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }

  updateRole(payload: RoleInterface): Promise<RoleInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const { id, ...rest } = payload
        const result = await this.axiosInstance.put(`/role/${id}`, rest)
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.SUCCESS,
            text: `Вы отредактировали роль`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }

  deleteRole(params: DeleteParams): Promise<RoleInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.delete<RoleInterface>(
          `/role/${params.id}`,
        )
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text: `Вы удалили роль`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }

  /// Device...
  createDevice(payload: DeviceItemInterface): Promise<DeviceItemInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.post('/local-device', payload)
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.SUCCESS,
            text: `Вы создали устройство`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }
  getDeviceList(params: DeviceListParams): Promise<DeviceListResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.get<DeviceListResponse>(
          '/local-device',
          { params },
        )

        resolve(result.data)
      } catch (error: any) {
        reject(error)
      }
    })
  }

  getDeviceListByParkingList(
    params: DeviceListParams,
  ): Promise<DeviceListResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.get<DeviceListResponse>(
          '/local-device/by-parking-list',
          { params },
        )

        resolve(result.data)
      } catch (error: any) {
        reject(error)
      }
    })
  }
  updateDevice(payload: DeviceItemInterface): Promise<DeviceItemInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const { id, ...rest } = payload
        const result = await this.axiosInstance.put(`/local-device/${id}`, rest)
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.SUCCESS,
            text: `Вы отредактировали устройство`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }
  deleteDevice(params: DeleteParams): Promise<DeviceItemInterface> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.delete<DeviceItemInterface>(
          `/local-device/${params.id}`,
        )
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text: `Вы удалили устройство`,
          }),
        )
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }

  openBarrier(payload: OpenBarrierPayload): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.axiosInstance.post(`/barrier`, payload)
        resolve(result.data)
      } catch (error: any) {
        this.store.dispatch(
          addNotification({
            id: v4(),
            type: NotificationTypes.ERROR,
            text:  error.response ?error.response.data.message : 'Неизвестная ошибка',
          }),
        )
        reject(error)
      }
    })
  }

  getPlaces(params: GetOneParams): Promise<PlacesResponse | null> {
    return new Promise(async (resolve, reject) => {
      try {
        const result =
          await this.axiosInstance.get<PlacesResponse | null>(
            `/place/places/${params.id}`,
          )
        resolve(result.data)
      } catch (error) {
        reject(error)
      }
    })
  }
}

export const getApi = () => {
  if (!api) api = new Api(baseUrl)

  return api
}
