import React from 'react'
import classes from './Subscriptions.module.sass'
import { PageHeader } from '../../common/components/pageHeader/PageHeader'
import { Button } from '../../common/components/button/Button'
import ticket from '../../assets/images/icons/Ticket.svg'
import { Modal } from '../../common/components/modal/Modal'
import { Form } from './components/form/Form'
import PlusCircle from '../../assets/images/icons/PlusCircle.svg'
import { useSubscriptions } from './hooks/useSubscriptions'
import { SubscriptionItem } from './components/subscriptionItem/SubscriptionItem'
import { CounterBookmark } from '../../common/components/counterBookmark/CounterBookmark'
import { Pagination } from '../../common/components/pagination/Pagination'
import { Action, Subject } from '../../common/interfaces/interfaces'
import { ListLoader } from './../../common/components/listLoader/ListLoader';

export const Subscriptions = () => {
  const {
    list,
    modalActive,
    setModalActive,
    startEdit,
    startCreate,
    initialValue,
    edit,
    getList,
    message,
    loading,
    toggleActive,
    totalCount,
    limit,
    offset,
    setOffset,
    active,
    activeCount,
    checkAbility,
    printSubscription
  } = useSubscriptions()

  return (
    <div className={classes.Container}>
      <div className={classes.HeaderAndButtonWrapper}>
        <PageHeader length={totalCount} icon_props={ticket} />
        <div className={classes.BookmarksAndAdd}>
          <CounterBookmark
            active={active}
            allLength={totalCount}
            activeLength={activeCount}
            toggleActive={toggleActive}
          />
          <Button
            text="Добавить"
            onClick={startCreate}
            icon={PlusCircle}
            disabled={!checkAbility(Action.Create, Subject.Subscriptions)}
          />
        </div>
      </div>

      <div className={classes.PassesListContainer}>
        {list.length > 0 ? (
          list.map((subscription) => (
            <SubscriptionItem
            printSubscription = {printSubscription}
              key={subscription.id}
              subscription={subscription}
              startEdit={startEdit}
            />
          ))
        ) : !loading ? (
          <div className={classes.LoaderContainer}>
            <span className={classes.Message}>{message}</span>
          </div>
        ) : (
          <ListLoader />
        )}
      </div>

      <Pagination
        totalCount={!active ? totalCount : activeCount}
        limit={limit}
        offset={offset}
        setOffset={setOffset}
      />

      <Modal modalActive={modalActive} setModalActive={setModalActive}>
        <Form
          setModalActive={setModalActive}
          initialValue={initialValue}
          edit={edit}
          getList={getList}
        />
      </Modal>
    </div>
  )
}
