import React from 'react'
import classes from './LocalDevice.module.sass'
import { PageHeader } from '../../common/components/pageHeader/PageHeader'
import { Button } from '../../common/components/button/Button'
import PlusCircle from '../../assets/images/icons/PlusCircle.svg'
import { Action, Subject } from '../../common/interfaces/interfaces'
import { useLocalDevice } from './hooks/useLocalDevice'
import { CounterBookmark } from '../../common/components/counterBookmark/CounterBookmark'
import { Modal } from '../../common/components/modal/Modal'
import { Form } from './components/form/Form'
import { ListLoader } from '../../common/components/listLoader/ListLoader'
import { DeviceItem } from './components/deviceItem/DeviceItem'
import { Pagination } from '../../common/components/pagination/Pagination'
import parkingIcon from '../../assets/images/icons/parkingIcon.svg'

export const LocalDevice = () => {
  const {
    list,
    modalActive,
    setModalActive,
    edit,
    initialValue,
    startEdit,
    startCreate,
    getList,
    loading,
    message,
    toggleActive,
    totalCount,
    limit,
    offset,
    setOffset,
    active,
    activeCount,
    checkAbility,
    checkDeviceActive,
  } = useLocalDevice()

  return (
    <div className={classes.Container}>
      <div className={classes.Container}>
        <div className={classes.HeaderAndButtonWrapper}>
          <PageHeader length={totalCount} icon_props={parkingIcon} />
          <div className={classes.BookmarksAndAdd}>
            <CounterBookmark
              active={active}
              allLength={totalCount}
              activeLength={activeCount}
              toggleActive={toggleActive}
            />
            <Button
              text={'Добавить'}
              icon={PlusCircle}
              onClick={startCreate}
              disabled={!checkAbility(Action.Create, Subject.LocalDevices)}
            />
          </div>
        </div>
      </div>

      <div className={classes.ListContainer}>
        {list.length > 0 ? (
          list.map((device) => (
            <DeviceItem
              active={checkDeviceActive(device.deviceId)}
              el={device}
              key={device.id}
              startEdit={startEdit}
            />
          ))
        ) : !loading ? (
          <div className={classes.LoaderContainer}>
            <span className={classes.Message}>{message}</span>
          </div>
        ) : (
          <ListLoader />
        )}
      </div>

      <Pagination
        totalCount={!active ? totalCount : activeCount}
        limit={limit}
        offset={offset}
        setOffset={setOffset}
      />

      <Modal modalActive={modalActive} setModalActive={setModalActive}>
        <Form
          setModalActive={setModalActive}
          edit={edit}
          initialValue={initialValue}
          getList={getList}
        />
      </Modal>
    </div>
  )
}
