import React from 'react'
import classes from './LeftSideMenu.module.sass'
import { menuLinks } from './constants'
import { MenuItem } from './components/menuItem/MenuItem'
import { useLeftSideMenu } from './hooks/useLeftSideMenu'
import logo from '../../../assets/images/logo.svg'
import burger_active from '../../../assets/images/icons/burger_active.svg'

type Props = {
  menuActive: boolean
  toggleMenu: () => void
}

export const LeftSideMenu = ({ menuActive, toggleMenu }: Props) => {
  const { activeItem, toggle, logOut } = useLeftSideMenu()

  return (
    <div className={!menuActive ? classes.Container : classes.ContainerMobile}>
      <div className={!menuActive ? classes.InMenu : classes.InMenuMobile}>
        <div className={classes.LogoAndClose}>
          <img className={classes.Logo} src={logo} alt="Логотип" />
          <img
            className={classes.CloseIcon}
            src={burger_active}
            alt="Зкрыть меню"
            onClick={toggleMenu}
          />
        </div>
        {menuLinks.map((link) => (
          <MenuItem
            link={link}
            key={link.id}
            toggleMenu={toggleMenu}
            menuActive={menuActive}
            activeItem={activeItem}
            toggle={toggle}
          />
        ))}
        <div className={classes.LogOut} onClick={logOut}>
          Выйти
        </div>
      </div>
    </div>
  )
}
