import classes from './SubscriptionItem.module.sass'
import {
  Action,
  Subject,
  SubscriptionItemInterface,
} from '../../../../common/interfaces/interfaces'
import { CarSign } from '../../../../common/components/carSign/CarSign'
import { format } from 'date-fns'
import { ListButton } from '../../../../common/components/listButton/ListButton'
import { Status } from '../../../../common/components/status/Status'
import { useAbility } from '../../../../common/hooks/useAbility'
import { useSubscriptionItem } from './hooks/useSubscriptionItem'

type Props = {
  subscription: SubscriptionItemInterface
  startEdit: (subscriptionItem: SubscriptionItemInterface) => void
  printSubscription: (id: string) => void
}

export const SubscriptionItem = ({
  subscription,
  startEdit,
  printSubscription,
}: Props) => {
  const { checkAbility } = useAbility()
  const {calculateDaysLeft, calculateEndDate} = useSubscriptionItem()

  return (
    <div className={classes.Container}>
      <div className={classes.ContentWrapper}>
        <div className={classes.PassName}>{subscription.name}</div>
        <CarSign number={subscription.carNumber} />
        <div className={classes.Date}>
          <div className={classes.DateItem}>
            <span className={classes.BeforeDate}>Дата покупки:</span>{' '}
            {format(new Date(subscription.saleDate), 'dd.MM.yyyy')}
          </div>
          <div className={classes.DateItem}>
            <span className={classes.BeforeDate}>Срок действия:</span>{' '}
            {format(new Date(calculateEndDate(subscription.saleDate, subscription.validity)), 'dd.MM.yyyy HH:mm')}
          </div>
        </div>
        <div className={classes.NotSpentBlock}>
          <span className={classes.BeforeDate}>Остаток:</span>
          <div className={classes.BlackSquared}>
            {calculateDaysLeft(subscription.saleDate, subscription.validity)} <span className={classes.DaysVisits}>дней</span>
          </div>
          {/* <div className={classes.BlackSquared}>
            {subscription.visitsLeft}{' '}
            <span className={classes.DaysVisits}>визитов</span>
          </div> */}
        </div>
        <div className={classes.Cost}>
          {subscription.cost}
          <span className={classes.Rur}>руб.</span>
        </div>
        <div className={classes.HorizontalLine} />
        <div className={classes.StatusAndButtonsWrapper}>
          {subscription.placeReserve && <div>Бронь места</div>}
          <div className={classes.Active}>
            <Status
              title={{ positive: 'Активен', negative: 'Не активен' }}
              fixedWith="120px"
              active_props={subscription.active && subscription.expireDate && new Date (subscription.expireDate) > new Date() }
            />
          </div>
          <div className={classes.VerticalLine} />
          <ListButton
            disabled={!checkAbility(Action.Update, Subject.Subscriptions)}
            title={'Печать'}
            type={'print'}
            onClick={() =>
              subscription.id && printSubscription(subscription.id)
            }
          />
          <ListButton
            disabled={!checkAbility(Action.Update, Subject.Subscriptions)}
            title={'Редактировать'}
            type={'edit'}
            onClick={() => startEdit(subscription)}
          />
        </div>{' '}
      </div>
    </div>
  )
}
