import { useState } from 'react'
import { useCurrentUser } from '../../../../../hooks/useCurrentUser'

export const useUserInfo = () => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const {currentUser} = useCurrentUser()


  return {  currentUser }
}
