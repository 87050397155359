import { useCallback, useEffect, useState } from 'react'
import { DeviceItemInterface } from '../../../common/interfaces/interfaces'
import { getApi } from '../../../api/api'
import { useLogout } from '../../../common/hooks/useLogout'
import { useCurrentUser } from '../../../common/hooks/useCurrentUser'
import { useAbility } from '../../../common/hooks/useAbility'
import { useSelector } from 'react-redux'
import { selectDeviceStateList } from '../../../store/slices/deviceStateListSlice'

const initialValuerPattern: DeviceItemInterface = {
  id: '',
  name: '',
  deviceId: '',
  parkingId: '',
  partnerId: '',
  isActive: false,
}

export const useLocalDevice = () => {
  const [modalActive, setModalActive] = useState<boolean>(false)
  const [list, setList] = useState<DeviceItemInterface[]>([])
  const [edit, setEdit] = useState(false)
  const [initialValue, setInitialValue] = useState<DeviceItemInterface>(
    structuredClone(initialValuerPattern),
  )

  const [api] = useState(() => getApi())
  const { logOut } = useLogout()
  const { currentUser } = useCurrentUser()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  const deviceStateList = useSelector(selectDeviceStateList)

  // Pagination ...
  const [activeCount, setActiveCount] = useState<number>(0)
  const [active, setActive] = useState<boolean>(false)
  const [offset, setOffset] = useState(0)
  const [limit] = useState(8)
  const [totalCount, setTotalCount] = useState<number>(0)

  const { checkAbility } = useAbility()

  const startEdit = (item: DeviceItemInterface) => {
    setEdit(true)
    setInitialValue(item)
    setModalActive(true)
  }

  const startCreate = () => {
    setEdit(false)
    setInitialValue(structuredClone(initialValuerPattern))
    setModalActive(true)
  }

  const getList = useCallback(
    async (reUsed?: boolean) => {
      try {
        if (!reUsed) {
          setList([])
          setLoading(true)
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 700))
        }

        const result = await api.getDeviceList({
          _start: offset.toString(),
          _end: (offset + limit).toString(),
          _sort: '_id',
          _order: 'desc',
          isActive: active ? active : '',
        })
        setModalActive(false)
        setList(result.data)
        setTotalCount(result.total)
        result.totalActive && setActiveCount(result.totalActive)
        if (!reUsed) {
          setLoading(false)
          setMessage('Записей нет')
        }
      } catch (error: any) {
        if (error.response &&  error.response.status === 401) logOut().then()
        if (!reUsed) {
          setLoading(false)
          setMessage('роизошла ошибка во время запроса, попробуйте позже')
        }
      }
    },
    [
      api,
      logOut,
      limit,
      offset,
      active,
      setActiveCount,
      setTotalCount,
      currentUser.partnerId,
    ],
  )

  const toggleActive = () => {
    setActive((prev) => !prev)
  }

  useEffect(() => {
    getList()
  }, [api, limit, offset, active])

  const checkDeviceActive = (id: string) => {
    const device = deviceStateList.find((el) => el.deviceId === id)
    if (device) return true
    return false
  }

  return {
    list,
    modalActive,
    setModalActive,
    edit,
    setEdit,
    initialValue,
    startEdit,
    startCreate,
    getList,
    loading,
    message,
    toggleActive,
    totalCount,
    limit,
    offset,
    setOffset,
    active,
    activeCount,
    checkAbility,
    checkDeviceActive,
  }
}
